/** @jsx jsx */
import { jsx, Box, Heading, Spinner, Grid, Text, Divider } from 'theme-ui'
import React from 'react'
import { useQuery } from '@apollo/client'
import { useLocation } from 'react-use'
import queryString from 'query-string'
import { path, compose } from 'ramda'
import { parseISO, addDays } from 'date-fns/fp'

import { GET_BOOKING_BY_TOKEN } from '../../queries/bookings'
import { eventDateTime } from '../../utils/dates'

import { SEO } from 'components'

const BookingConfirmation = React.lazy(() =>
  import('../../components/booking-confirmation')
)

export default function BookingSuccess() {
  const location = useLocation()

  const query = queryString.parse(location.search)
  const token = query?.token

  const { data, loading } = useQuery(GET_BOOKING_BY_TOKEN, {
    variables: { token },
    skip: !token,
  })

  const booking = data?.booking

  const isSSR = typeof window === 'undefined'

  if (loading) {
    return (
      <Box px={['m', null, 'l']}>
        <Box variant="boxes.container" sx={{ marginX: 'auto', paddingY: 'm' }}>
          <Spinner />
        </Box>
      </Box>
    )
  }

  return (
    <>
      <SEO title="You’re booked in!" />
      {!isSSR && (
        <React.Suspense fallback={<Spinner />}>
          <BookingConfirmation booking={booking} />
        </React.Suspense>
      )}
    </>
  )
}
